/* Ant Layout Style */
.ant-layout {
  position: relative;
  background: none;
  font-family: 'NoirPro', sans-serif !important;
}
.ant-layout > .gx-layout-content {
  overflow-x: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.full-scroll .ant-layout > .gx-layout-content {
  overflow-x: inherit;
}
.ant-layout.ant-layout-has-sider > .ant-layout {
  position: relative;
  background: #f5f5f5;
}
.framed-layout .ant-layout,
.boxed-layout .ant-layout {
  background: #f5f5f5;
}
.framed-layout .ant-layout,
.boxed-layout .ant-layout {
  background: #f5f5f5;
}
.ant-layout.gx-app-layout {
  height: 100vh;
}
.framed-layout .ant-layout.gx-app-layout {
  height: calc(100vh - 2 * 20px);
}
.framed-layout.full-scroll .ant-layout.gx-app-layout,
.boxed-layout.full-scroll .ant-layout.gx-app-layout {
  overflow-y: scroll;
}
.ant-layout.gx-app-layout > .ant-layout {
  height: 100vh;
}
.framed-layout .ant-layout.gx-app-layout > .ant-layout {
  height: calc(100vh - 2 * 20px);
}
.full-scroll .ant-layout.ant-layout-has-sider > .ant-layout,
.full-scroll .ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: inherit;
}
.gx-main-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  flex: 1;
  height: 100%;
}
.gx-container {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 992px) {
  .gx-container {
    width: 944px;
  }
}
@media screen and (min-width: 1200px) {
  .gx-container {
    width: 1128px;
  }
}
@media screen and (min-width: 1400px) {
  .gx-container {
    width: 1300px;
  }
}
@media screen and (min-width: 1567px) {
  .gx-container {
    width: 1400px;
  }
}
@media screen and (min-width: 1600px) {
  .gx-container {
    width: 1400px;
  }
}
.gx-main-content-wrapper {
  padding: 32px 32px 0;
  flex: 1;
}
@media screen and (max-width: 768px) {
  .gx-main-content-wrapper {
    padding: 20px 15px 0;
  }
}
.gx-container-wrap .gx-main-content-wrapper {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 992px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 944px;
  }
}
@media screen and (min-width: 1200px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1128px;
  }
}
@media screen and (min-width: 1400px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1300px;
  }
}
@media screen and (min-width: 1567px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1400px;
  }
}
@media screen and (min-width: 1600px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1400px;
  }
}
@media screen and (max-width: 1200px) {
  .gx-header-horizontal-main .gx-user-nav {
    margin-left: 0;
  }
}
@media screen and (max-width: 1400px) {
  .gx-inside-header-horizontal .gx-header-horizontal-main .gx-user-nav {
    margin-left: 0;
  }
}
.gx-avatar {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: hidden;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  height: 50px;
  width: 50px;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: solid 1px #495762;
}
@media screen and (max-width: 1200px) {
  .gx-user-nav .gx-avatar {
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
}
@media screen and (max-width: 768px) {
  .gx-user-nav .gx-avatar {
    height: 30px;
    width: 30px;
    line-height: 30px;
  }
}
.gx-header-horizontal-main .gx-avatar {
  height: 40px;
  width: 40px;
  line-height: 40px;
}
@media screen and (max-width: 1200px) {
  .gx-header-horizontal-main .gx-avatar {
    height: 30px;
    width: 30px;
    line-height: 30px;
  }
}
.gx-user-popover {
  margin: -12px -16px;
  padding: 7px 0;
  list-style: none;
}
.gx-user-popover li {
  cursor: pointer;
  padding: 3px 15px;
  width: 150px;
}
.gx-user-popover li:hover,
.gx-user-popover li:focus {
  background-color: #fafafa;
}
@media screen and (max-width: 768px) {
  .gx-user-popover li {
    width: 100%;
  }
}
.gx-ml-auto,
.gx-mx-auto {
  margin-left: auto !important;
}
.gx-header-notifications {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-header-notifications > li {
  font-size: 18px;
}
.gx-header-notifications > li:not(:last-child) {
  margin-right: 42px;
}
.gx-inside-header-horizontal .gx-header-notifications > li:not(:last-child) {
  margin-right: 20px;
}
.gx-header-horizontal-main .gx-header-notifications > li:not(:last-child),
.gx-header-horizontal-nav .gx-header-notifications > li:not(:last-child) {
  margin-right: 30px;
}
@media screen and (max-width: 1200px) {
  .gx-header-horizontal-main .gx-header-notifications > li:not(:last-child),
  .gx-header-horizontal-nav .gx-header-notifications > li:not(:last-child) {
    margin-right: 16px;
  }
}
@media screen and (max-width: 1200px) {
  .gx-header-notifications > li:not(:last-child) {
    margin-right: 16px;
  }
}
.gx-header-notifications > li.gx-notify {
  margin-right: 22px;
}
.gx-header-horizontal-main .gx-header-notifications > li.gx-notify {
  margin-right: 30px;
}
@media screen and (max-width: 1200px) {
  .gx-header-horizontal-main .gx-header-notifications > li.gx-notify {
    margin-right: 16px;
  }
}
.gx-inside-header-horizontal .gx-header-notifications > li.gx-notify {
  margin-right: 20px;
}
@media screen and (max-width: 1200px) {
  .gx-header-notifications > li.gx-notify {
    margin-right: 16px;
  }
}
.gx-header-notifications > li.gx-language {
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .gx-header-notifications > li.gx-language .gx-language-name,
  .gx-header-notifications > li.gx-language .icon {
    display: none;
  }
  .gx-header-notifications > li.gx-language .flag.flag-24 {
    width: 20px;
    height: 20px;
    margin-top: -4px;
  }
}
@media screen and (max-width: 768px) {
  .gx-header-notifications > li.gx-notify,
  .gx-header-notifications > li.gx-msg {
    margin-top: 2px;
  }
  .gx-header-notifications > li.gx-language {
    margin-bottom: 2px;
  }
}
.gx-header-notifications > li.gx-user-nav {
  cursor: pointer;
}
.gx-mb-4,
.gx-my-4 {
  margin-bottom: 1.5rem !important;
}
.gx-text-center {
  text-align: center !important;
}
.editText input {
  background: inherit;
}
.gx-table-responsive .ant-table {
  min-height: 0.01%;
  overflow-x: auto;
}
textarea {
  min-height: 200px!important;
  max-height: 500px;
}
.no-wrap {
  white-space: nowrap;
}
/* Header Style */
.ant-layout-header {
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  line-height: 1;
  padding: 0 32px;
  height: 72px;
  position: relative;
  z-index: 10;
  color: #262626;
}
@media screen and (max-width: 1200px) {
  .ant-layout-header {
    padding: 0 15px;
  }
}
@media screen and (max-width: 768px) {
  .ant-layout-header {
    height: 50px;
  }
}
.gx-header-horizontal {
  position: relative;
}
.gx-header-horizontal-dark {
  position: relative;
}
.gx-header-horizontal-dark > div {
  position: relative;
  z-index: 2;
}
.gx-header-horizontal-top {
  padding: 12px 0;
  font-size: 14px;
}
.gx-header-horizontal-top-flex,
.gx-header-horizontal-nav-flex,
.gx-header-horizontal-main-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-header-horizontal-top-left {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-right: 15px;
}
@media screen and (max-width: 992px) {
  .gx-header-horizontal-top-left {
    margin-right: 0;
    width: 100%;
  }
}
.gx-header-horizontal-main {
  display: -webkit-block;
  display: -ms-blockbox;
  display: -ms-block;
  display: block;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 25px 0 35px;
  height: auto;
  background-color: transparent;
}
@media screen and (max-width: 1400px) {
  .gx-header-horizontal-main {
    padding: 20px 0;
  }
  .gx-header-horizontal-main .gx-w-logo {
    padding: 7px 5px;
  }
}
@media screen and (max-width: 1200px) {
  .gx-header-horizontal-main {
    padding: 0;
  }
}
@media screen and (max-width: 768px) {
  .gx-header-horizontal-main .gx-w-logo {
    padding-right: 0;
  }
}
.gx-inside-header-horizontal .gx-header-horizontal-main {
  padding: 0;
}
/*Base Styles*/
body {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
body ::-webkit-scrollbar {
  width: 5px;
}
body ::-webkit-scrollbar-track {
  background: #ededed;
  border-radius: 5px;
}
body ::-webkit-scrollbar-thumb {
  width: 50px;
  border-radius: 5px;
  background: #38424b;
}
body ::-webkit-scrollbar-thumb:hover {
  background: #434f5a;
}
#root {
  background-color: #22282e;
  color: #e0e0e0;
}
.gx-login-content {
  background-color: #434f5a;
}
.gx-login-content .ant-input {
  background-color: #bfbfbf;
}
.gx-login-content .ant-input:focus {
  border-color: #38424b;
}
.ant-popover-inner,
.ant-popover-arrow {
  background-color: #434f5a;
}
.ant-popover-inner {
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.28);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.28);
}
.ant-popover-inner-content,
.ant-popover-message {
  color: #e0e0e0;
}
.ant-popover-title {
  border-bottom-color: #495762;
  color: #e0e0e0;
}
.ant-popover-placement-bottomRight {
  padding-top: 10px;
}
.gx-user-popover li:hover,
.gx-user-popover li:focus {
  background-color: #4e5c69;
}
a {
  color: #e0e0e0;
}
a:hover {
  color: inherit;
}
.gx-link {
  color: #c7c7c7;
}
.gx-link:hover,
.gx-link:focus {
  color: #e8e8e8;
}
.gx-social-link li .gx-link {
  color: #e0e0e0;
}
.gx-social-link li.active .gx-link,
.gx-social-link li .gx-link:hover,
.gx-social-link li .gx-link:focus {
  color: #ffffff;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #ededed;
}
.ant-modal h1,
.ant-modal h2,
.ant-modal h3,
.ant-modal h4,
.ant-modal h5,
.ant-modal h6,
.ant-modal .h1,
.ant-modal .h2,
.ant-modal .h3,
.ant-modal .h4,
.ant-modal .h5,
.ant-modal .h6 {
  color: #38424b;
}
.gx-drawer-sidebar-dark .ant-drawer-content {
  background-color: #343d45;
  color: #e0e0e0;
}
.ant-drawer-right .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content {
  background: #38424b;
}
.ant-drawer-close {
  color: #e0e0e0;
}
.ant-drawer-close:hover,
.ant-drawer-close:focus {
  color: #ffffff;
}
.ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout {
  background: #38424b;
}
.ant-layout-header {
  background: #38424b;
  color: #e0e0e0;
}
.gx-header-horizontal-main {
  color: #e0e0e0;
  background: none;
}
.gx-header-notifications {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-header-notifications > li {
  font-size: 18px;
}
.gx-header-notifications > li:not(:last-child) {
  margin-right: 42px;
}
.gx-inside-header-horizontal .gx-header-notifications > li:not(:last-child) {
  margin-right: 20px;
}
.gx-header-horizontal-main .gx-header-notifications > li:not(:last-child),
.gx-header-horizontal-nav .gx-header-notifications > li:not(:last-child) {
  margin-right: 30px;
}
@media screen and (max-width: 1200px) {
  .gx-header-horizontal-main .gx-header-notifications > li:not(:last-child),
  .gx-header-horizontal-nav .gx-header-notifications > li:not(:last-child) {
    margin-right: 16px;
  }
}
@media screen and (max-width: 1200px) {
  .gx-header-notifications > li:not(:last-child) {
    margin-right: 16px;
  }
}
.gx-header-notifications > li.gx-notify {
  margin-right: 22px;
}
.gx-header-horizontal-main .gx-header-notifications > li.gx-notify {
  margin-right: 30px;
}
@media screen and (max-width: 1200px) {
  .gx-header-horizontal-main .gx-header-notifications > li.gx-notify {
    margin-right: 16px;
  }
}
.gx-inside-header-horizontal .gx-header-notifications > li.gx-notify {
  margin-right: 20px;
}
@media screen and (max-width: 1200px) {
  .gx-header-notifications > li.gx-notify {
    margin-right: 16px;
  }
}
@media screen and (max-width: 768px) {
  .gx-header-notifications > li.gx-notify,
  .gx-header-notifications > li.gx-msg {
    margin-top: 2px;
  }
  .gx-header-notifications > li.gx-language {
    margin-bottom: 2px;
  }
}
.gx-header-horizontal-main .gx-header-notifications li > span {
  color: #545454;
}
.gx-header-horizontal-dark .gx-header-horizontal-main .gx-header-notifications li > span {
  color: #fa8c15;
}
.ant-menu-horizontal > .ant-menu-item a:hover,
.ant-menu-horizontal > .ant-menu-item-active {
  color: #038fde;
}
.gx-header-horizontal {
  background: #2d353c;
}
.gx-header-horizontal-main {
  color: #e0e0e0;
  background: none;
}
.gx-header-horizontal-dark:before {
  background: #434f5a;
}
/*Login Styles*/
.gx-login-container {
  position: relative;
  height: 100%;
  padding-bottom: 30px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-login-content {
  max-width: 420px;
  width: 94%;
  margin: auto;
  padding: 35px 35px 20px;
  background-color: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  font-size: 14px;
  background-color: #434f5a;
}
.gx-login-content .ant-form-item-control-wrapper {
  width: 100%;
}
.gx-login-content .ant-form-item-children {
  display: block;
}
.ant-input {
  background-color: rgba(250, 250, 250, 0.1) !important;
  border-color: rgba(250, 250, 250, 0.1);
  color: #e0e0e0;
}
.ant-input:focus,
.ant-input:hover {
  border-color: rgba(245, 245, 245, 0.1);
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.ant-input-number {
  background-color: rgba(250, 250, 250, 0.1);
  border-color: rgba(250, 250, 250, 0.1);
  color: #e0e0e0;
}
.ant-input-number:focus,
.ant-input-number:hover {
  border-color: rgba(245, 245, 245, 0.1);
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #495762;
}
.ant-input-group-addon {
  background-color: rgba(250, 250, 250, 0.1);
  border-color: rgba(250, 250, 250, 0.1);
  color: #e0e0e0;
}
.ant-input-group-addon .ant-select-open .ant-select-selection,
.ant-input-group-addon .ant-select-focused .ant-select-selection {
  color: #e0e0e0;
}
.ant-input-search-icon {
  color: #e0e0e0;
}
.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
  color: #e0e0e0;
}
.ant-input-affix-wrapper .ant-input-prefix i,
.ant-input-affix-wrapper .ant-input-suffix i {
  color: #e0e0e0 !important;
}
.ant-modal-body textarea {
  background-color: #f5f5f5 !important;
  border-color: #f5f5f5;
  color: #262626;
}
.ant-modal-body textarea:focus {
  border-color: #495762;
}
.gx-login-header {
  margin-bottom: 30px;
}
.gx-app-login-wrap {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  overflow-x: hidden;
}
.gx-app-login-container {
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto;
}
.gx-app-login-container .gx-loader-view {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.gx-app-login-header {
  margin-bottom: 30px;
}
.gx-app-logo-content {
  width: 40%;
  position: relative;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  color: #e0e0e0;
}
.gx-app-logo-content h1 {
  color: #e0e0e0;
}
.gx-app-logo-content:before {
  background-color: rgba(56, 66, 75, 0.7);
}
.gx-app-logo-content img {
  width: 100%;
  height: 100%;
}
.editText input:focus {
  border: none;
  outline: none;
  border-bottom: 1px solid #495762;
}
.ant-form-item,
.ant-form-item-label label,
.ant-form-explain,
.ant-form-extra {
  color: #e0e0e0;
}
.ant-btn {
  background-color: #38424b;
  border-color: #38424b;
  color: #e0e0e0;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #e0e0e0;
  background-color: #323a43;
  border-color: #323a43;
}
.ant-btn-primary {
  background-color: #323a43 !important;
  border-color: #323a43 !important;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #293037 !important;
  border-color: #293037 !important;
}
.ant-btn-primary:not([disabled]):not(.disabled):active,
.ant-btn-primary:not([disabled]):not(.disabled).active {
  background-color: #293037 !important;
  border-color: #293037 !important;
}
.ant-btn.disabled,
.ant-btn:disabled {
  background-color: #2d353d !important;
  border-color: #2d353d !important;
  color: #ffffff;
}
.ant-btn-background-ghost.ant-btn-primary {
  background-color: #323a43 !important;
  border-color: #323a43 !important;
  color: #ffffff !important;
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  background-color: #293037 !important;
  border-color: #293037 !important;
  color: #ffffff !important;
}
.ant-card {
  color: #e0e0e0;
  background: #434f5a;
}
.ant-card-bordered {
  border-color: #495762;
}
.ant-card-head {
  border-color: #495762;
}
.ant-card-head-title {
  color: #e0e0e0;
}
.ant-card-type-inner .ant-card-head {
  background: #434f5a;
}
.ant-card-meta-title,
.ant-card-meta-description {
  color: #e0e0e0;
}
.ant-card-actions {
  border-top-color: #495762;
  background: #434f5a;
}
.ant-card-actions > li {
  color: #e0e0e0;
}
.ant-card-actions > li:not(:last-child) {
  border-right-color: #495762;
}
.ant-card-actions > li > span:hover {
  color: #e8e8e8;
}
.ant-card-grid {
  box-shadow: 1px 0 0 0 #495762, 0 1px 0 0 #495762, 1px 1px 0 0 #495762, 1px 0 0 0 #495762 inset, 0 1px 0 0 #495762 inset;
}
.ant-card-grid:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table {
  color: #e0e0e0;
}
.ant-table table {
  border-collapse: collapse;
}
.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  background: inherit;
  border: none;
}
.ant-table .ant-empty-description {
  color: #e0e0e0;
}
.ant-table-thead > tr > th {
  background: #4e5c69;
  color: #e0e0e0;
  border-bottom-color: #495762;
}
.ant-table-thead > tr:hover > td,
.ant-table-tbody > tr:hover > td,
.ant-table-body > tr:hover > td {
  background: #4e5c69;
}
.ant-table-tbody > tr > td,
.ant-table-body > tr > td {
  border-bottom-color: #495762;
}
.ant-table-tbody > tr.ant-table-row-selected td,
.ant-table-body > tr.ant-table-row-selected td {
  background: #4e5c69;
}
.ant-table-small {
  border-color: #495762;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th {
  background: #4e5c69;
  border-bottom-color: #495762;
}
.ant-table.ant-table-bordered .ant-table-title,
.ant-table-bordered .ant-table-header > table,
.ant-table-bordered .ant-table-body > table,
.ant-table-bordered .ant-table-fixed-left table,
.ant-table-bordered .ant-table-fixed-right table,
.ant-table.ant-table-bordered .ant-table-footer {
  border-color: #495762;
}
.ant-table-header {
  background: #4e5c69;
}
.ant-table-footer {
  background: #4e5c69;
}
.ant-table-footer:before {
  background: transparent;
}
.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td,
.ant-table-bordered .ant-table-body > tr > td {
  border-right-color: #495762;
}
.ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
  border-bottom-color: #495762;
}
.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  background: #434f5a;
}
.ant-table-fixed-left table,
.ant-table-fixed-right table {
  background: #4e5c69;
}
.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  color: #e0e0e0;
}
.ant-table-thead > tr > th .anticon-filter:hover,
.ant-table-thead > tr > th .ant-table-filter-icon:hover {
  color: #ffffff;
}
.ant-table-row-expand-icon {
  background: none;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #4e5c69;
}
.ant-checkbox-wrapper {
  margin-right: 10px;
  padding: 24px 0;
  color: inherit;
}
.ant-checkbox-checked:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-checked:focus + .ant-checkbox-inner {
  border-color: #38424b;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #38424b;
  border-color: #38424b !important;
}
.ant-select {
  color: #e0e0e0;
}
.ant-select-selection {
  background-color: rgba(250, 250, 250, 0.1);
  border-color: rgba(250, 250, 250, 0.1);
}
.ant-select-selection:hover {
  border-color: rgba(250, 250, 250, 0.1);
}
.ant-select-arrow {
  color: #e0e0e0;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: rgba(245, 245, 245, 0.1);
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.ant-select-dropdown {
  color: #e0e0e0;
  background-color: #38424b;
}
.ant-select-dropdown-menu-item {
  color: #e0e0e0;
}
.ant-select-dropdown-menu-item:hover {
  background-color: #343d46;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #343d46;
}
.ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item-selected:hover,
.ant-select-dropdown-menu-item-active {
  background-color: #323a43;
  color: #e0e0e0;
}
.ant-select-dropdown-menu-item-selected:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item-selected:hover:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #323a43;
  color: #e0e0e0;
}
.ant-select-auto-complete.ant-select .ant-input:focus,
.ant-select-auto-complete.ant-select .ant-input:hover {
  border-color: rgba(250, 250, 250, 0.1);
}
.ant-select-dropdown-menu-item-group-title {
  color: #e0e0e0;
}
.ant-select-auto-complete.ant-select textarea.ant-input {
  background: rgba(250, 250, 250, 0.1) !important;
}
.ant-select-tree,
.ant-select-tree li .ant-select-tree-node-content-wrapper {
  color: #e0e0e0;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper:hover,
.ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #4e5c69;
}
.ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field,
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  border-color: #4e5c69;
  background-color: #4e5c69;
}
.ant-pagination {
  color: #e0e0e0;
}
.ant-pagination-item {
  background-color: #38424b;
  border-color: #495762;
}
.ant-pagination-item-active,
.ant-pagination-item:hover,
.ant-pagination-item:focus {
  border-color: #495762;
}
.ant-pagination-item-active .gx-link,
.ant-pagination-item:hover .gx-link,
.ant-pagination-item:focus .gx-link {
  color: #e0e0e0;
}
.ant-pagination-disabled a,
.ant-pagination-disabled:hover a,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  color: #6d6d6d;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  color: #e0e0e0;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background-color: #38424b;
  border-color: #495762;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #495762;
  color: #e0e0e0;
}
.ant-pagination-jump-prev:focus:after,
.ant-pagination-jump-next:focus:after,
.ant-pagination-jump-prev:hover:after,
.ant-pagination-jump-next:hover:after {
  color: #e0e0e0;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  background-color: rgba(250, 250, 250, 0.1);
  border-color: rgba(250, 250, 250, 0.1);
  color: #e0e0e0;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: rgba(250, 250, 250, 0.3);
}
.ant-pagination-options-quick-jumper input {
  background-color: rgba(250, 250, 250, 0.1);
  border-color: rgba(250, 250, 250, 0.1);
  color: #e0e0e0;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: rgba(250, 250, 250, 0.1);
}
.ant-pagination-options-quick-jumper input:focus {
  border-color: #495762;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.ant-tabs {
  color: #e0e0e0;
}
.ant-tabs a {
  color: inherit;
}
.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #c7c7c7;
}
.ant-tabs-ink-bar {
  background-color: #c7c7c7;
}
.ant-tabs-bar {
  border-bottom-color: #495762;
}
.ant-tabs-tab-prev,
.ant-tabs-tab-next {
  color: #e0e0e0;
}
.ant-tabs-tab-prev:hover,
.ant-tabs-tab-next:hover {
  color: #ffffff;
}
.ant-tabs-tab-btn-disabled,
.ant-tabs-tab-btn-disabled:hover {
  color: #7a7a7a;
}
.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: #546471;
  border-bottom-color: #434f5a;
  background: #475460;
}
.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  background: #434f5a;
  border-color: #546471;
  border-bottom-color: #434f5a;
  color: #ffffff;
}
.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab .anticon-close {
  color: #e0e0e0;
}
.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab .anticon-close:hover {
  color: #ffffff;
}
.ant-breadcrumb {
  padding: 24px 0;
}
.ant-breadcrumb a,
.ant-breadcrumb span {
  color: #e0e0e0 !important;
}
.ant-modal h1,
.ant-modal h2,
.ant-modal h3,
.ant-modal h4,
.ant-modal h5,
.ant-modal h6,
.ant-modal .h1,
.ant-modal .h2,
.ant-modal .h3,
.ant-modal .h4,
.ant-modal .h5,
.ant-modal .h6 {
  color: #38424b;
}
.ant-modal .ant-input {
  background-color: #ffffff !important;
  border-color: #dde2e6;
  color: #595959;
}
.ant-modal .ant-input:focus,
.ant-modal .ant-input:hover {
  border-color: #cfd6db;
}
.ant-upload-select-picture-card {
  background-color: rgba(250, 250, 250, 0.1) !important;
  border: 1px dashed rgba(250, 250, 250, 0.1) !important;
  color: #e0e0e0;
}
.copy_row {
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.copy_row span {
  text-decoration: underline;
}
.copy_row span:hover {
  cursor: pointer;
}
.ant-menu {
  color: #e0e0e0;
  background: #343d45;
}
.ant-menu .ant-menu-item > a {
  color: #e0e0e0;
}
.gx-header-horizontal-nav .ant-menu-horizontal {
  color: #e0e0e0;
  background: none;
  border-bottom: 2px solid transparent;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 2px solid transparent;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background: none;
  color: #038fde;
  border-bottom: 2px solid #038fde;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected a,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected a {
  color: #038fde;
}
@media screen and (max-width: 768px) {
  .ant-layout-sider {
    flex: auto!important;
    max-width: auto!important;
    min-width: auto!important;
    width: 100px!important;
  }
}
.ant-layout-sider .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.12);
}
.ant-layout-sider.gx-mini-custom-sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
.ant-layout-sider.gx-mini-custom-sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a:hover {
  color: #003366;
}
.time {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: no-wrap;
  -ms-flex-wrap: no-wrap;
  flex-wrap: no-wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.time input {
  padding: 5px;
  text-align: right;
  width: 45px;
  margin-right: 10px;
}
.log_time {
  margin: 15px 0;
}
.log_time th {
  font-weight: 600;
}
.log_time th:first-child {
  width: 30%;
}
.log_time td {
  vertical-align: middle;
  padding: 0.75rem;
}
.log_time td:first-child {
  font-size: 0.8rem;
}
.log_time td:last-child {
  width: 10%;
}
.log_time td:nth-child(7) .time > input,
.log_time td:nth-child(8) .time > input {
  background: #38424b !important;
}
.log_time tbody span {
  cursor: pointer;
}
.log_time input + span {
  margin-left: 10px;
  font-size: 0.8rem;
}
.log_time .highlighted {
  border-bottom: 2px solid #495762;
}
.loader {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: no-wrap;
  -ms-flex-wrap: no-wrap;
  flex-wrap: no-wrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  margin-top: 150px;
  font-size: 24px;
}
.text-center {
  text-align: center;
}
.project-assign > div {
  color: #e0e0e0;
}
.print_header {
  display: none;
}
.editText input:focus {
  border: none;
  outline: none;
  border-bottom: 1px solid #495762;
}
.modalSelect .ant-form-item,
.modalSelect .ant-form-item-label label,
.modalSelect .ant-form-explain,
.modalSelect .ant-form-extra {
  color: #38424b;
}
.modalSelect .ant-select-focused .ant-select-selection,
.modalSelect .ant-select-selection:focus,
.modalSelect .ant-select-selection:active {
  border-color: #e8e8e8;
}
.modalSelect .ant-select-selection {
  border-color: #e8e8e8;
  color: #38424b;
}
.btn_print {
  margin: 0 1rem;
}
.link {
  padding: 0.5rem;
}
.text-right {
  text-align: right;
}
.detailed_report thead > tr > td:last-child {
  width: 10%;
}
.ant-calendar {
  background-color: #4e5c69;
  border-color: #4e5c69;
  color: #e0e0e0;
}
.ant-calendar-picker [aria-label="icon: close-circle"] {
  display: none;
}
.ant-calendar-picker:first-child {
  margin-right: 15px;
}
.ant-calendar-today .ant-calendar-date {
  border-color: #7e8f9f;
  color: #7e8f9f;
  background-color: #647586;
}
.ant-calendar-selected-date .ant-calendar-date,
.ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-selected-end-date .ant-calendar-date,
.ant-calendar-selected-day .ant-calendar-date {
  background: #7e8f9f;
  color: #e0e0e0;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover,
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover,
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade,
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover {
  background: #7e8f9f;
  color: #e0e0e0;
}
.ant-calendar-month-panel-month:hover,
.ant-calendar-year-panel-year:hover,
.ant-calendar-decade-panel-decade:hover {
  background: #647586;
}
.ant-calendar-input {
  color: #e0e0e0;
  background-color: #4e5c69;
}
.ant-calendar-month-panel,
.ant-calendar-year-panel,
.ant-calendar-decade-panel {
  background-color: #4e5c69;
}
.ant-calendar-header,
.ant-calendar-input-wrap,
.ant-calendar-month-panel-header,
.ant-calendar-year-panel-header,
.ant-calendar-decade-panel-header {
  border-bottom-color: #546471;
}
.ant-calendar-footer,
.ant-calendar-range .ant-calendar-body,
.ant-calendar-range .ant-calendar-month-panel-body,
.ant-calendar-range .ant-calendar-year-panel-body {
  border-top-color: #546471;
}
.ant-calendar-picker-icon,
.ant-calendar-picker-icon:after {
  color: #e0e0e0;
}
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #495762;
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-prev-month-btn,
.ant-calendar-header .ant-calendar-next-month-btn,
.ant-calendar-header .ant-calendar-prev-year-btn,
.ant-calendar-header .ant-calendar-next-year-btn,
.ant-calendar-header .ant-calendar-century-select,
.ant-calendar-header .ant-calendar-decade-select,
.ant-calendar-header .ant-calendar-year-select,
.ant-calendar-header .ant-calendar-month-select {
  color: #e0e0e0;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select {
  color: #e0e0e0;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select {
  color: #e0e0e0;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  color: #e0e0e0;
}
.ant-calendar-date {
  color: #e0e0e0;
}
.ant-calendar-date:hover {
  background: #647586;
}
.ant-calendar-last-month-cell .ant-calendar-date,
.ant-calendar-next-month-btn-day .ant-calendar-date,
.ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year,
.ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year,
.ant-calendar-decade-panel-last-century-cell .ant-calendar-decade-panel-decade,
.ant-calendar-decade-panel-next-century-cell .ant-calendar-decade-panel-decade {
  color: #adadad;
}
.ant-calendar-picker-clear {
  color: #e0e0e0;
  background: none;
}
.ant-calendar .ant-calendar-ok-btn {
  color: #495762 !important;
  background-color: #7e8f9f !important;
  border-color: #7e8f9f !important;
}
.ant-calendar .ant-calendar-ok-btn:hover,
.ant-calendar .ant-calendar-ok-btn:focus {
  color: #38424b !important;
  background-color: #9ba8b5 !important;
  border-color: #38424b !important;
}
.ant-calendar-range .ant-calendar-in-range-cell:before {
  background-color: #52616e;
}
.ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
  background: #52616e;
}
.ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day .ant-calendar-date,
.ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day:hover .ant-calendar-date,
.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn-disabled {
  color: #e0e0e0;
}
.customSelect {
  max-width: 250px;
}
.customSelect span + div:hover {
  border: none;
  box-shadow: none;
}
.customSelect span + div:focus {
  outline: none;
}
.customSelect span + div + div {
  background: #434f5a;
}
.customSelect span + div + div:focus {
  outline: none;
}
.customSelect span + div + div:hover {
  border: none;
  box-shadow: none;
}
.customSelect:focus {
  outline: none;
}
.checkbox .ant-checkbox-wrapper {
  padding: 0;
}
.gx-mr-3,
.gx-mx-3 {
  margin-right: 1rem !important;
}
/*Status style*/
.gx-status-pos {
  position: relative;
}
.gx-status-pos .anticon {
  font-size: 18px;
}
.gx-status-pos .gx-status {
  display: block;
  position: absolute;
  left: 0;
  top: 2px;
  z-index: 1;
  width: 8px;
  height: 8px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.gx-status-pos .gx-status-rtl {
  top: -4px;
  left: 14px;
}
.gx-status-pos .gx-blink {
  background-color: #038fde;
  display: block;
  -webkit-box-shadow: 0 0 0 rgba(3, 143, 222, 0.4);
  -moz-box-shadow: 0 0 0 rgba(3, 143, 222, 0.4);
  box-shadow: 0 0 0 rgba(3, 143, 222, 0.4);
  animation: away 2s infinite;
}
@keyframes away {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(3, 143, 222, 0.4);
    box-shadow: 0 0 0 0 rgba(3, 143, 222, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(3, 143, 222, 0);
    box-shadow: 0 0 0 10px rgba(3, 143, 222, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(3, 143, 222, 0);
    box-shadow: 0 0 0 0 rgba(3, 143, 222, 0);
  }
}
