@media print {
  .nav,
  .gx-header-horizontal,
  [role="tablist"],
  .ant-card-head,
  .react-datepicker-wrapper, .d-print-none {
    display: none;
  }
  .ant-card-bordered{
    border: none;
    color: black;
  }
  .ant-table {
    color: black;
  }
  .ant-table tr > td {
    border-bottom: 1px solid #E0E0E0 ;
  }
  a:link {
    text-decoration: none;
  }

  #table-to-xls .ant-table-thead > tr > th {
    background: #E0E0E0!important;
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
    color: black;
    border-bottom: 1px solid #E0E0E0;
  }

  #table-to-xls  th {
    color: black!important;
  }

  .table-secondary, .table-secondary>td, .table-secondary>th{
    background: rgb(231, 231, 231)!important;
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  .print_header {
    padding-top: 10px;
    line-height: 0.5;
    display: block !important;
  }

   #table-to-xls .highlighted {
    border-bottom: 1px solid #E0E0E0;
  }

  .log_time td:first-child{
    font-weight: 400;
  }
  .print_header div{
    margin: 20px 0;
  }
  .print_header a{
    color: rgb(103, 103, 252);
  }
  .print_header .d-flex{
    margin-bottom: 50px;
  }
  .logo {
    max-width: 270px;
    max-height: 100px;
  }
  .logo img {
    width: 100%;
    height: 100%;
  }

  .ant-layout.gx-app-layout {
    height: auto;
  }
}

